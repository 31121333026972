import { DruideTextField } from "@yoshteq/druide-webcomponents";
import { Card, CardType, ChangePinResponse, Connector, Log, PinResult, PinStatus, PinType, StatusEvent, VerifyPinResponse } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { TiPortal } from "./TiPortal";

@customElement("enter-pin-dialog")
export class EnterPinDialog extends LitElement {

    @property({ attribute: false })
    card?: Card;

    @state()
    content?: TemplateResult;

    @query("#pin-input")
    pinInput?: DruideTextField;

    private remotePinCapable = false;

    pinType?: PinType;

    private connector?: Connector;

    errorTemplate?: TemplateResult;

    private get cardName() {
        return this.card?.type;
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.content = this.renderLoadingView("Daten werden geladen");

        if (this.card?.type === CardType.SMC_B) {
            this.pinType = "PIN.SMC";
        } else if (this.card?.type === CardType.EGK) {
            this.pinType = "PIN.CH";
        } else if (this.card?.type === CardType.HBA && !this.pinType) {
            this.pinType = "PIN.CH";
        }

        this.verifyPinState();
    }

    private async verifyPinState() {
        try {
            this.connector = await TiPortal.tiSession.getConnector();

            const pinStatus = await TiPortal.tiSession.cardService.getPinStatus(this.card!, this.pinType!);
            if (pinStatus.pinStatus === PinStatus.VERIFIED) {
                this.handlePinSuccess();
            } else if (pinStatus.pinStatus === PinStatus.VERIFIABLE) {
                this.remotePinCapable = await TiPortal.tiSession.cardTerminalService.hasRemotePinInput(this.card!);
                this.content = this.renderPinEnterView();
            } else if (pinStatus.pinStatus === PinStatus.TRANSPORT_PIN || pinStatus.pinStatus === PinStatus.BLOCKED) {
                this.content = this.renderPinChangeView();
            } else {
                this.content = this.renderError(`Die PIN befindet sich im Zustand ${pinStatus.pinStatus} und kann nicht entsperrt werden`);
            }
        } catch (e) {
            Log.error("Could not verify PIN state", e);
            this.content = this.renderError(`Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.`);
        }
    }

    private handlePinSuccess() {
        StatusEvent.log(`Konnektor und ${this.cardName} Karte betriebsbereit.`);
        this.requestUpdate();
        if (this.pinType === "PIN.SMC") {
            StatusEvent.smbcUnlocked();
        } else {
            StatusEvent.hbaUnlocked();
        }
        this.dispatchEvent(new CustomEvent("pin-success"));
    }

    protected override render(): TemplateResult {
        if (!this.card?.handle || !this.pinType) {
            return html``;
        }

        return html`
            <druide-modal close-explicit visible>
                <druide-card style="width:360px;" label="Karte entsperren">
                    ${this.content ?? ''}
                </druide-card>
            </druide-modal>`;
    }

    private renderLoadingView(statusMessage: string) {
        return html`<loader-message>${statusMessage}</loader-message>`;
    }

    private renderPinEnterView() {
        if (this.remotePinCapable) {
            return this.renderRemotePinEnterView();
        } else {
            return this.renderManualPinEnterView();
        }
    }

    private renderRemotePinEnterView() {
        return html`
            ${this.renderPinEnterInfo()}
            <div @submit=${this.startRemotePinVerification}>
                <druide-text-field label="${this.cardName} Pin"  id="pin-input" type="password" required style="display:block"></druide-text-field>
                <druide-button slot="footer-right" title="PIN senden" submit></druide-button>
            </div>`;
    }

    private async startRemotePinVerification() {
        const pin = this.pinInput!.value;
        if (pin.length > 5) {
            try {
                this.content = this.renderLoadingView("Die Remote-PIN Eingabe wird durchgeführt");
                const response = await TiPortal.tiSession.cardTerminalService.enterPinForSmcb(this.card!, pin);
                this.handleVerifyPinResponse(response);
            } catch (e) {
                this.remotePinCapable = false;
                this.content = this.renderRetryView(html`<div>Die Remote PIN Eingabe konnte nicht gestartet werden. Sie können gerne eine manuelle PIN Eingabe am Kartenterminal starten.</div>`);
            }
        } else {
            this.pinInput!.error = "Bitte geben Sie eine Pin von mindesten 5 Stellen ein.";
        }
    }

    private renderManualPinEnterView() {
        return html`
            ${this.renderPinEnterInfo()}
            <div>
                Zum Entsperren müssen Sie auf den Button drücken und dann auf dem Kartenterminal Ihre ${this.cardName} PIN eingeben.
            </div>
            <druide-button slot="footer-right" title="PIN Eingabe jetzt starten" @click=${this.startManualPinVerification}></druide-button>`;
    }

    private async startManualPinVerification() {
        this.content = this.renderLoadingView("Bitte geben Sie die PIN am Kartenterminal ein");
        try {
            const response = await this.connector!.getCardService().verifyPin(this.card!.handle, this.pinType!);
            this.handleVerifyPinResponse(response);
        } catch (e) {
            Log.error(e);
            this.content = this.renderRetryView(html`Fehler`);
        }
    }

    private handleVerifyPinResponse(response: VerifyPinResponse) {
        if (response.pinStatus === PinResult.OK) {
            this.handlePinSuccess();
        } else {
            const verifyPinErrorCode = response.status.error?.trace?.code;
            let errorTemplate: TemplateResult;
            if (verifyPinErrorCode === "4043") {
                errorTemplate = html`
                    <div style="color: red">
                        Karte konnte nicht entsperrt werden.
                        Ihre Eingabe hat zu lange gedauert, weshalb die PIN Eingabe vom Kartenterminal abgebrochen wurde.
                    </div style="color: red">`;
            } else {
                const statusText = response.status.error?.trace?.errorText ?? response.pinStatus;
                errorTemplate = html`
                    <div>
                        Karte konnte nicht entsperrt werden.
                        Kartenterminal meldet: ${statusText}<br>
                        Möchten Sie es erneut versuchen? Sie haben noch ${response.leftTries} Versuche.
                    </div>`;
            }
            this.content = this.renderRetryView(errorTemplate);
        }
    }

    private renderPinEnterInfo() {
        return html`
            <div>
                Leider ist die ${this.cardName} Karte im momentan gesperrt.
                Sie müssen diese erst mit ihrer PIN entsperren bevor Sie die weiter machen können.
            </div>`;
    }

    private renderPinChangeView() {
        return html`
            <div>
                Die ${this.cardName} Karte ist mit der Transport PIN gesichert. Zur Nutzung müssen Sie zunächst eine PIN setzen.<br><br>
                Klicken Sie hierzu auf "PIN ändern" und geben dann zunächst die Transport PIN und anschließend 2x die gewünschte PIN ein.<br><br>
            </div>
            <druide-button slot="footer-right" title="PIN ändern" @click=${() => this.changePin()}></druide-button>`;
    }

    private async changePin() {
        this.content = this.renderLoadingView("Bitte die Schritte zur Änderung der PIN am Karteneterminal durchführen");
        const response = await this.connector!.getCardService().changePin(this.card?.handle!, this.pinType!);
        if (response.pinStatus === PinResult.OK) {
            this.handlePinSuccess();
        } else {
            this.content = this.handleChangePinResponse(response);
        }
        this.requestUpdate();
    }

    private handleChangePinResponse(changePinResponse: ChangePinResponse) {
        return html`<div style="color: red">Die Änderung der PIN war nicht erfolgreich: ${changePinResponse.pinStatus}</div>`;
    }

    private renderRetryView(errorTemplate: TemplateResult) {
        return html`
            ${errorTemplate}
            ${this.renderPinEnterView()}
        `;
    }

    private renderError(message: string) {
        return html`
            <div>
                ${message}
            </div>`;
    }

}
